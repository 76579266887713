import styled from "styled-components";
import { createTheme } from '@mui/material/styles';


export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;
  margin: 0 auto;
  gap: 3rem;
`

export const Title = styled.h1`
  text-align: center;
`;

export const Container = styled.div`
  min-height: 100vh;
  //background: linear-gradient(145deg, rgb(244, 115, 142) 0%, rgb(252, 182, 116) 100%);
  background: linear-gradient(145deg, rgb(102, 46, 155) 0%, rgb(67, 188, 205) 100%);
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

export const FieldContainer = styled.div`
  width: 100%;
`;

export const ErrorText = styled.div`
  color: red;
`;

export const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    mode: 'dark',
  },
});
